/* SLIDE UP */
const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.boxSizing = `border-box`
  target.style.height = target.offsetHeight + `px`
  target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  window.setTimeout(() => {
    target.style.display = `none`
    target.style.removeProperty(`height`)
    target.style.removeProperty(`padding-top`)
    target.style.removeProperty(`padding-bottom`)
    target.style.removeProperty(`margin-top`)
    target.style.removeProperty(`margin-bottom`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
    // alert("!")
  }, duration)
}

/* SLIDE DOWN */
const slideDown = (target, duration = 500) => {
  target.style.removeProperty(`display`)
  let display = window.getComputedStyle(target).display
  if (display === `none`) {
    display = `block`
  }
  target.style.display = display
  const height = target.offsetHeight
  target.style.overflow = `hidden`
  target.style.height = 0
  target.style.paddingTop = 0
  target.style.paddingBottom = 0
  target.style.marginTop = 0
  target.style.marginBottom = 0
  target.offsetHeight
  target.style.boxSizing = `border-box`
  target.style.transitionProperty = `height, margin, padding`
  target.style.transitionDuration = duration + `ms`
  target.style.height = height + `px`
  target.style.removeProperty(`padding-top`)
  target.style.removeProperty(`padding-bottom`)
  target.style.removeProperty(`margin-top`)
  target.style.removeProperty(`margin-bottom`)
  window.setTimeout(() => {
    target.style.removeProperty(`height`)
    target.style.removeProperty(`overflow`)
    target.style.removeProperty(`transition-duration`)
    target.style.removeProperty(`transition-property`)
  }, duration)
}

/* TOOGGLE */
const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration)
  } else {
    return slideUp(target, duration)
  }
}

(function ($) {
  $(document).ready(function () {
    svg4everybody({})
  })

})(jQuery)

// set vh height
window.addEventListener("resize",function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})
// set vh height end

window.addEventListener("load",function(){
  let body = this.document.querySelector("body");
  let html = this.document.querySelector("html");

  // set vh height
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // set vh height end

  // SELECT ===================================================
  function closeSelect() {
    selects.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
      }
    })
    dropDowns.forEach(item => {
      if (item.classList.contains(('active'))) {
        item.classList.remove('active')
        slideUp(item, 100)
      }
    })
  }
  const selectsInside = document.querySelector('.selects-inside')
  const selectTrigger = document.querySelectorAll('.select__trigger')
  const selects = document.querySelectorAll('.selecter')
  const dropDowns = document.querySelectorAll('.select__dropdown')
  const dropDownOption = document.querySelectorAll('.select__option')
  selectTrigger && selectTrigger.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const dropdown = container.querySelector('.select__dropdown')
      const selectsItems = [...selects].filter(item => item !== container)
      const dropDownsItems = [...dropDowns].filter(item => item !== dropdown)
      selectsItems.forEach(item => {
        item.classList.remove('active')
      })
      dropDownsItems.forEach(item => {
        item.classList.remove('active')
        slideUp(item, 100)
      })
      // closeSelect()
      if (!container.classList.contains('active')) {
        container.classList.add('active')
        dropdown && dropdown.classList.add('active')
        slideDown(dropdown, 100)
      } else {
        container.classList.remove('active')
        dropdown && dropdown.classList.remove('active')
        slideUp(dropdown, 100)
      }
    })
  })

  selectsInside && selectsInside.addEventListener('click', function (e) {
    const target = e.target
    if (!target.closest('.selecter')) {
      closeSelect()
    }
  })

  dropDownOption && dropDownOption.forEach(item => {
    item.addEventListener('click', function () {
      const container = this.closest('.selecter')
      const valueItem = container.querySelector('.select__value')
      const input = container.querySelector('.select__input')
      const options = container.querySelectorAll('.select__option')
      const formItem = this.closest('.form-item')
      const trigger = container.querySelector('.select__trigger')

      if (trigger.classList.contains('placeholder')) {
        trigger.classList.remove('placeholder')
      }

      if (formItem) {
        if (formItem.classList.contains('error')) {
          formItem.classList.remove('error')
        }
      }
      const value = this.getAttribute('data-value')
      if (valueItem) {
        valueItem.innerHTML = value
      }
      input.value = value
      options.forEach(item => {
        item.classList.remove('active')
      })
      this.classList.add('active')
      closeSelect()
    })
  })
  // END SELECT =======================================================

  // header menu ======================================================
  let headerMenu = document.querySelector(".header-mobile__menu")
  let headerBurger = document.querySelector(".header__burger")
  let overlay = document.querySelector(".overlay")
  if(headerMenu && headerBurger){
    let header = headerMenu.closest(".header")
    // header functions
    let removeActiveDrop = function(){
      let activeOptionLink = document.querySelector(".header__link.active")
      if(activeOptionLink){activeOptionLink.classList.remove("active")}
      let activeOptionList = document.querySelector(".header-link__hidelist.active")
      if(activeOptionList){
        activeOptionList.classList.remove('active')
        slideUp(activeOptionList,200)
      }
    }

    let closeMenu = function(){
      headerMenu.classList.remove("active")
      headerBurger.classList.remove("active")
      body.classList.remove("lock")
      html.classList.remove("lock")
      if(overlay){
        overlay.classList.remove("active")
      }
    }

    let scrollHeader = function(){
      if(window.scrollY > 0 && !header.classList.contains('active')){
        header.classList.add("active")
      }else if(window.scrollY == 0 && header.classList.contains('active')){
        header.classList.remove("active")
      }
    }
    scrollHeader()

    // header functions end

    headerBurger.addEventListener('click',function(){
      headerMenu.classList.toggle("active")
      headerBurger.classList.toggle("active")
      body.classList.toggle("lock")
      html.classList.toggle("lock")
      if(window.scrollY == 0){
        header.classList.toggle("active")
      }
      if(overlay){
        overlay.classList.toggle("active")
      }
    })

    // anchor
    let anchorTrigger = document.querySelectorAll(".anchor-trigger")
    let anchorBlock = document.querySelectorAll(".anchor-block")
    if(anchorTrigger && header){
      anchorTrigger.forEach(trigger=>{
        trigger.addEventListener("click",function(e){
          removeActiveDrop()
          if(trigger.classList.contains("header__logo") || trigger.classList.contains("footer__logo")){
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            })
          }
          if(anchorBlock){
            if(anchorBlock.length >= 1){
              e.preventDefault()
              anchorBlock.forEach(b=>{
                if(trigger.hasAttribute("data-anchor") && b.hasAttribute("data-block")){
                  if(trigger.getAttribute("data-anchor").toLowerCase() == b.getAttribute("data-block").toLowerCase()){
                    let boundingBlock = b.getBoundingClientRect().top
                    let boundingCount = window.scrollY + boundingBlock
                    window.scrollTo({
                      top: boundingCount - header.offsetHeight - 35,
                      behavior: "smooth"
                    })
                    if(headerMenu.classList.contains("active")){
                      closeMenu()
                    }
                  }
                }
              })
            }
          }
        })
      })
    }
    // anchor end

    // header dropdowns
    let headerOptions = document.querySelectorAll(".header-link__option")
    if(headerOptions){
      headerOptions.forEach(option=>{
        let optionTrigger = option.querySelector(".header__link")
        let optionList = option.querySelector(".header-link__hidelist")
        if(optionTrigger && optionList){
          optionTrigger.addEventListener("click",function(){
            if(!optionTrigger.classList.contains('active')){
              removeActiveDrop()
            }
            // add current
            optionTrigger.classList.toggle("active")
            optionTrigger.style.pointerEvents = "none"
            optionList.classList.toggle("active")
            slideToggle(optionList,200)
            setTimeout(function(){
              optionTrigger.style.pointerEvents = "all"
            },200)
            // add current end
          })

          // close on window click
          selectsInside && selectsInside.addEventListener('click', function (e) {
            let clickTarget = e.target
            if (!clickTarget.closest('.header-link__option')) {
              removeActiveDrop()
            }
          })
          // close on window click end
        }
      })
    }
    // header dropdowns end

    window.addEventListener("resize",function(){
      if(window.innerWidth > 1024 && headerMenu.classList.contains("active")){
        closeMenu()
        removeActiveDrop()
        if(window.scrollY == 0 && header.classList.contains('active')){
          header.classList.remove('active')
        }
      }
    })

    window.addEventListener("scroll",function(){
      scrollHeader()
    })
  }
  // header menu end ======================================================

  // modals  ======================================================
  let modalsTrigger = document.querySelectorAll(".modal-trigger__btn")
  let modalsBox = document.querySelectorAll(".modal-wraper")
  if(modalsTrigger && modalsBox){
    modalsTrigger.forEach(trigerModal=>{
      trigerModal.addEventListener("click",function(){
        modalsBox.forEach(modalBox=>{
          if(trigerModal.getAttribute("data-type").toLowerCase() == modalBox.getAttribute("data-modal").toLowerCase()){
            modalBox.classList.add("active")
            body.classList.add("lock")
            html.classList.add("lock")
            let callTargetInput = modalBox.querySelector(".from-target-input")
            if(callTargetInput){
              callTargetInput.value = trigerModal.getAttribute("data-place")
            }
          }
        })
      })
    })
    // close popup
    modalsBox.forEach(box=>{
      let closeModalFunc = function(){
        let headerMenu = document.querySelector(".header-mobile__menu")
        if(headerMenu && headerMenu.classList.contains('active')){}else{
          body.classList.remove("lock")
          html.classList.remove("lock")
        }
        box.classList.remove("active")
      }
      let closeModal = box.querySelectorAll(".modal-close__trigger")
      if(closeModal){
        closeModal.forEach(btnclose=>{
          btnclose.addEventListener("click", closeModalFunc)

        })
      }
      window.addEventListener("click",function(e){
        if(e.target == box){
          closeModalFunc()
        }
      })
    })
    // close popup end
  }
  // modals end  ======================================================

  // itnl   ==========================================================
  let inputCountry = document.querySelectorAll(".country-number-field")
  if(inputCountry){
    inputCountry.forEach(inputState=>{
      if(inputState.classList.contains("eng-country")){
        let iti = intlTelInput(inputState,{
          onlyCountries: ["us", "gb", "ua", "at", "bg", "cz", "dk", "ee", "fi", "fr", "de", "is", "ie", "it", "lv", "nl", "no", "pl", "sk", "si", "es", "se", "ch"],
          initialCountry: 'US',
          autoHideDialCode: false,
          preferredCountries: ["UA","GB", "US"],
          // separateDialCode: true,
          nationalMode:false,
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
        })
      }else{
        let iti = intlTelInput(inputState,{
          onlyCountries: ["us", "gb", "ua", "at", "bg", "cz", "dk", "ee", "fi", "fr", "de", "is", "ie", "it", "lv", "nl", "no", "pl", "sk", "si", "es", "se", "ch"],
          initialCountry: 'UA',
          autoHideDialCode: false,
          preferredCountries: ["UA","GB", "US"],
          // separateDialCode: true,
          nationalMode:false,
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
        })
      }
    })
  }
  // itnl end   ========================================================

  // name validate ======================================================
  let nameFileds = document.querySelectorAll(".name-validate-field")
  if(nameFileds){
    nameFileds.forEach(nameField => {
      nameField.addEventListener("input",function(e){
        nameField.value = nameField.value.replace(/[^a-z A-Z а-я А-я Ї ї І і ]/g, "");
      })
    })
  }
  // name validate end ==================================================


  //validate form ======================================================
  const forms = document.querySelectorAll(".form-container")
  forms && forms.forEach(item => {
    // email patern
    let email = item.querySelector(".mail")
    if (email) {
      let parent = email.closest(".form-item")
      let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      email.addEventListener("keyup", function (ev) {
        ev.stopPropagation();
        let value = email.value;
        if (value.match(pattern)) {
          parent.classList.remove("error__email")
        } else {
          parent.classList.add("error__email")
        }
        if (value.length < 1) {
          parent.classList.remove("error__email")
        }
      })
    }
    // email patern end
    item.addEventListener('submit', function (e) {
      e.preventDefault();
      let valide = true;
      const inputs = item.querySelectorAll('.form-required');

      // checkbox valid
      let checkbox=item.querySelector(".form__checkbox input")
      let checkboxParent=item.querySelector(".form__checkbox")
      if(checkbox){
        valide= false
        if(checkbox.checked){
          valide = true
          checkboxParent.classList.remove("error")
        }else{
          checkboxParent.classList.add("error")
          valide = false
        }
      }
      // checkbox valid end

      // empty fields valid
      inputs.forEach(element => {
        const parent = element.closest('.form-item');
        if (element.value.trim() === '') {
          parent.classList.add('error');
          valide = false;
        } else {
          parent.classList.remove('error');
        }
      })
       // empty fields valid end

      // email validate
      let emailField = item.querySelector(".mail")
      if(email){
        let emailParent = emailField.closest(".form-item")
        if(emailParent && emailParent.classList.contains("error__email")){
          valide = false;
        }
      }
      // email validate end

      if (valide) {
        fetch('/mail.php', {
          method: 'POST',
          body: new FormData(item)
        }).then(function (response) {
          let thanksPopup = document.querySelector('.thanks-popup')
          if (thanksPopup) {setTimeout(function () {thanksPopup.classList.add('active')}), 100}
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        }).then(function (data) {

        })["catch"](function (error) {
          let ErrorPopup = document.querySelector(".error-popup")
          if(ErrorPopup){setTimeout(function(){ErrorPopup.classList.add("active")}),100}
          console.warn(error);
        });

        // clear on sent
        let allInputs = item.querySelectorAll(".form__input")
        if(allInputs){
          allInputs.forEach(el=>{
            el.value = "";
          })
        }
        // clear on sent end

        // clear checkbox
        let checkboxInput = item.querySelectorAll('.form__checkbox input')
        if(checkboxInput){
          checkboxInput.forEach(checkForm=>{
            checkForm.checked = false
          })
        }
        // clear checkbox end

        // clear dropdowns
        let dropdownForm = item.querySelectorAll(".select-info.selecter")
        if(dropdownForm){
          dropdownForm.forEach(dropForm=>{
            let valueHolder = dropForm.querySelector(".select__value")
            let inputDrop = dropForm.querySelector("input")
            if(valueHolder && dropForm.hasAttribute("data-holder")){valueHolder.innerHTML = dropForm.getAttribute("data-holder")}
            if(inputDrop){inputDrop.value = ''}
            let activeOption = dropForm.querySelector(".select__option.active")
            if(activeOption){activeOption.classList.remove("active")}
          })
        }
        // clear dropdowns end

        // add country number
        let currentCountryFields = item.querySelectorAll(".country-number-field")
        if(currentCountryFields){
          currentCountryFields.forEach(inputStateField=>{
            let parentCountryField = inputStateField.closest(".form-item")
            if(parentCountryField){
              let activeCountryField = parentCountryField.querySelector(".iti__country.iti__active")
              if(activeCountryField){
                inputStateField.value = "+" + activeCountryField.getAttribute("data-dial-code")
              }
            }
          })
        }
        // add country number end

        // clear textarea
        let textareas = item.querySelectorAll(".form__textarea")
        if(textareas){
          textareas.forEach(area=>{
            area.value = ''
          })
        }
        // clear textarea end

        // close active popup on sent and add thanks popup
        let activePopup = document.querySelector(".modal-wraper.active")
        if(activePopup){activePopup.classList.remove("active")}

        // let thanksPopup = document.querySelector(".thanks-popup")
        // if(thanksPopup){setTimeout(function(){thanksPopup.classList.add("active")}),100}

        // let ErrorPopup = document.querySelector(".error-popup")
        // if(ErrorPopup){setTimeout(function(){ErrorPopup.classList.add("active")}),100}

        // close active popup on sent and add thanks popup end
      }
    })
  })
  // clear on input
  const inputsField = document.querySelectorAll('.form-container .form-item');
  inputsField.forEach(field=>{
    let inputField = field.querySelector("input")
    if(inputField){
      inputField.addEventListener("input",function(){
        field.classList.remove("error")
      })
    }
  })
  // clear on input end
  // validate form end  ======================================================


  // hero set year =======================================================
  let pricelistHeroYear = document.querySelector(".hero-pricelist__year")
  if(pricelistHeroYear){
    let dateYear = new Date
    dateYear = dateYear.getFullYear()
    pricelistHeroYear.innerHTML = dateYear
  }
  // hero set year end ===================================================

  // back to top btn =======================================================
  let backToTopBtn = document.querySelector(".back-to-top")
  backToTopBtn && backToTopBtn.addEventListener("click",function(){
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  })
  // back to top btn end ===================================================

  document.getElementById("year").innerHTML = new Date().getFullYear();
})
